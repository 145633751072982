const eventStreamPresets = [
  {
    name: 'Purchase Fail (simple)',
    eventTypes: ['PurchaseMade', 'GoldAmountChanged', 'SupportTicketCreated'],
    trackProps: ['gold'],
    mode: 'showOnly'
  },
  {
    name: 'Purchase Fail',
    eventTypes: [
      'PurchaseMade',
      'GoldAmountChanged',
      'saPurchaseStarted',
      'saPurchaseFailed',
      'saPurchase',
      'SupportTicketCreated'
    ],
    trackProps: ['gold'],
    mode: 'showOnly'
  },
  {
    name: 'VIP Time Lost',
    eventTypes: [
      'GameLaunched',
      'BabySlugEventProgress',
      'MinutiasMostWantedEventProgress',
      'GrindChallengeCompleted',
      'LostTreasureEventProgress'
    ],
    trackProps: ['VipInHours'],
    mode: 'showOnly'
  },
  {
    name: 'Ads',
    eventTypes: ['AdWheelSpinButtonPressed', 'AdWheelSpinCompleted', 'AdWheelVideoResult', 'saAdvertisementWatched'],
    trackProps: ['VipInHours'],
    mode: 'showOnly'
  },
  {
    name: 'Save prefer tracking',
    eventTypes: ['SavePreferResult'],
    trackProps: ['device', 'storyProgress'],
    mode: 'showOnly'
  },
  {
    name: 'Tournaments',
    eventTypes: [
      'TournamentsProgress',
      'GoldAmountChanged',
      'GoldenLevelAmountChanged',
      'SupportTicketCreated',
      'PsfRewardsFromPreviousSession'
    ],
    trackProps: ['gold'],
    mode: 'showOnly'
  }
];

export default eventStreamPresets;