const ukStates = [
  {
    name: 'Wales',
    code: 'wls'
  },
  {
    name: 'England',
    code: 'eng'
  },
  {
    name: 'Northern Ireland',
    code: 'nir'
  },
  {
    name: 'Scotland',
    code: 'sct'
  }
];

export default ukStates;