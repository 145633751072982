const locations = [
  {
    countryGroups: [
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'FI',
            name: 'Finland'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SE',
            name: 'Sweden'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'DK',
            name: 'Denmark'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'IS',
            name: 'Iceland'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'NO',
            name: 'Norway'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'IE',
            name: 'Ireland'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LV',
            name: 'Latvia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LT',
            name: 'Lithuania'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'EE',
            name: 'Estonia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AX',
            name: 'Åland Islands'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'FO',
            name: 'Faroe Islands'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'GG',
            name: 'Guernsey'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'IM',
            name: 'Isle of Man'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'JE',
            name: 'Jersey'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AQ',
            name: 'Antartica'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'GL',
            name: 'Greenland'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SJ',
            name: 'Svalbard and Jan Mayen'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BV',
            name: 'Bouvet Island'
          }
        ],
        code: 'NEU',
        name: 'Northern Europe'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'IT',
            name: 'Italia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'FR',
            name: 'France'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'ES',
            name: 'España'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'CH',
            name: 'Switzerland'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BE',
            name: 'Belgium'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'PT',
            name: 'Portugal'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'NL',
            name: 'Netherlands'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AD',
            name: 'Andorra'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'GI',
            name: 'Gibraltar'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LI',
            name: 'Liechtenstein'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LU',
            name: 'Luxembourg'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MT',
            name: 'Malta'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'VA',
            name: 'Vatican'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SM',
            name: 'San Marino'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MC',
            name: 'Monaco'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TF',
            name: 'French Southern Territories'
          }
        ],
        code: 'WEU',
        name: 'Western Europe'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'PL',
            name: 'Poland'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SK',
            name: 'Slovakia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'HU',
            name: 'Hungary'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'CZ',
            name: 'Czechia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AT',
            name: 'Österreich'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SI',
            name: 'Slovenia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'HR',
            name: 'Croatia'
          }
        ],
        code: 'CEU',
        name: 'Central Europe'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'RU',
            name: 'России Russia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TR',
            name: 'Türkiye'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'RO',
            name: 'Romania'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'UA',
            name: 'Ukraine'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'GR',
            name: 'Greece'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'RS',
            name: 'Serbia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BY',
            name: 'Беларусь Belarus'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BA',
            name: 'Bosnia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'XK',
            name: 'Kosovo'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BG',
            name: 'Bulgaria'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'GE',
            name: 'Georgia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AM',
            name: 'Armenia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MD',
            name: 'Moldova'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'IL',
            name: 'Israel'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MK',
            name: 'Macedonia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AL',
            name: 'Albania'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AZ',
            name: 'Azerbaijan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'CY',
            name: 'Cyprus'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'ME',
            name: 'Montenegro'
          }
        ],
        code: 'EEU',
        name: 'Eastern Europe'
      }
    ],
    countries: [
      {
        regionGroups: [],
        regions: [
          {
            code: 'ENG',
            name: 'England'
          },
          {
            code: 'WLS',
            name: 'Wales'
          },
          {
            code: 'NIR',
            name: 'Northern Ireland'
          },
          {
            code: 'SCT',
            name: 'Scotland'
          }
        ],
        code: 'GB',
        name: 'Great Britain'
      },
      {
        regionGroups: [],
        regions: [
          {
            code: 'BE',
            name: 'Berlin'
          },
          {
            code: 'BY',
            name: 'Bayern'
          },
          {
            code: 'BW',
            name: 'Baden-Württemberg'
          },
          {
            code: 'HB',
            name: 'Bremen'
          },
          {
            code: 'HH',
            name: 'Hamburg'
          },
          {
            code: 'HE',
            name: 'Hessen'
          },
          {
            code: 'MV',
            name: 'Mecklenburg-Vorpommern'
          },
          {
            code: 'NI',
            name: 'Niedersachsen'
          },
          {
            code: 'NW',
            name: 'Nordrhein-Westfalen'
          },
          {
            code: 'RP',
            name: 'Rheinland-Pfalz'
          },
          {
            code: 'SL',
            name: 'Saarland'
          },
          {
            code: 'SN',
            name: 'Sachsen'
          },
          {
            code: 'ST',
            name: 'Sachsen-Anhalt'
          },
          {
            code: 'SH',
            name: 'Schleswig-Holstein'
          },
          {
            code: 'TH',
            name: 'Thüringen'
          },
          {
            code: 'BR',
            name: 'Brandenburg'
          }
        ],
        code: 'DE',
        name: 'Deutschland'
      }
    ],
    code: 'EUR',
    name: 'Europe'
  },
  {
    countryGroups: [
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'GT',
            name: 'Guatemala'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'HN',
            name: 'Honduras'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'NI',
            name: 'Nicaragua'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SV',
            name: 'El Salvador'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'CR',
            name: 'Costa Rica'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'PA',
            name: 'Panama'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BZ',
            name: 'Belize'
          }
        ],
        code: 'CAM',
        name: 'Central America'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'CU',
            name: 'Cuba'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'DO',
            name: 'Dominican Republic'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'JM',
            name: 'Jamaica'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TT',
            name: 'Trinidad and Tobago'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BS',
            name: 'Bahamas'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'GP',
            name: 'Guadeloupe'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MQ',
            name: 'Martinique'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LC',
            name: 'Saint Lucia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'GD',
            name: 'Grenada'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'CW',
            name: 'Curaçao'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AW',
            name: 'Aruba'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'VC',
            name: 'Saint Vincent and the Grenadines'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AG',
            name: 'Antigua and Barbuda'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'DM',
            name: 'Dominica'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KN',
            name: 'Saint Kitts and Nevis'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SX',
            name: 'Sint Maarten'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MF',
            name: 'Saint Martin'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BB',
            name: 'Barbados'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'HT',
            name: 'Haiti'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AI',
            name: 'Anguilla'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BM',
            name: 'Bermuda'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KY',
            name: 'Cayman Islands'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'VG',
            name: 'Virgin Islands'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MS',
            name: 'Montserrat'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TC',
            name: 'TC Islands'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'VI',
            name: 'Virgin Islands'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'PR',
            name: 'Puerto Rico'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BL',
            name: 'Saint Barthélemy'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BQ',
            name: 'Carribbean Netherlands'
          }
        ],
        code: 'CRB',
        name: 'Caribbean'
      }
    ],
    countries: [
      {
        regionGroups: [],
        regions: [
          {
            code: 'TX',
            name: 'Texas'
          },
          {
            code: 'WA',
            name: 'Washington'
          },
          {
            code: 'AK',
            name: 'Alaska'
          },
          {
            code: 'AL',
            name: 'Alabama'
          },
          {
            code: 'AR',
            name: 'Arkansas'
          },
          {
            code: 'AZ',
            name: 'Arizona'
          },
          {
            code: 'CA',
            name: 'California'
          },
          {
            code: 'CO',
            name: 'Colorado'
          },
          {
            code: 'CT',
            name: 'Connecticut'
          },
          {
            code: 'DE',
            name: 'Delaware'
          },
          {
            code: 'DC',
            name: 'District of Columbia'
          },
          {
            code: 'FL',
            name: 'Florida'
          },
          {
            code: 'GA',
            name: 'Georgia'
          },
          {
            code: 'HI',
            name: 'Hawaii'
          },
          {
            code: 'IA',
            name: 'Iowa'
          },
          {
            code: 'ID',
            name: 'Idaho'
          },
          {
            code: 'IL',
            name: 'Illinois'
          },
          {
            code: 'IN',
            name: 'Indiana'
          },
          {
            code: 'KS',
            name: 'Kansas'
          },
          {
            code: 'KY',
            name: 'Kentucky'
          },
          {
            code: 'LA',
            name: 'Louisiana'
          },
          {
            code: 'MA',
            name: 'Massachusetts'
          },
          {
            code: 'MD',
            name: 'Maryland'
          },
          {
            code: 'ME',
            name: 'Maine'
          },
          {
            code: 'MI',
            name: 'Michigan'
          },
          {
            code: 'MN',
            name: 'Minnesota'
          },
          {
            code: 'MO',
            name: 'Missouri'
          },
          {
            code: 'MS',
            name: 'Mississippi'
          },
          {
            code: 'MT',
            name: 'Montana'
          },
          {
            code: 'NC',
            name: 'North Carolina'
          },
          {
            code: 'ND',
            name: 'North Dakota'
          },
          {
            code: 'NE',
            name: 'Nebraska'
          },
          {
            code: 'NH',
            name: 'New Hampshire'
          },
          {
            code: 'NJ',
            name: 'New Jersey'
          },
          {
            code: 'NM',
            name: 'New Mexico'
          },
          {
            code: 'NV',
            name: 'Nevada'
          },
          {
            code: 'NY',
            name: 'New York'
          },
          {
            code: 'OH',
            name: 'Ohio'
          },
          {
            code: 'OK',
            name: 'Oklahoma'
          },
          {
            code: 'OR',
            name: 'Oregon'
          },
          {
            code: 'PA',
            name: 'Pennsylvania'
          },
          {
            code: 'RI',
            name: 'Rhode Island'
          },
          {
            code: 'SC',
            name: 'South Carolina'
          },
          {
            code: 'SD',
            name: 'South Dakota'
          },
          {
            code: 'TN',
            name: 'Tennessee'
          },
          {
            code: 'UT',
            name: 'Utah'
          },
          {
            code: 'VA',
            name: 'Virginia'
          },
          {
            code: 'VT',
            name: 'Vermont'
          },
          {
            code: 'WI',
            name: 'Wisconsin'
          },
          {
            code: 'WV',
            name: 'West Virginia'
          },
          {
            code: 'WY',
            name: 'Wyoming'
          }
        ],
        code: 'US',
        name: 'United States'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CA',
        name: 'Canada'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MX',
        name: 'Mexico'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'PM',
        name: 'Saint Pierre and Miquelon'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'UM',
        name: 'United States Minor Outlying Islands'
      }
    ],
    code: 'AMN',
    name: 'North America'
  },
  {
    countryGroups: [],
    countries: [
      {
        regionGroups: [],
        regions: [],
        code: 'AU',
        name: 'Australia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NZ',
        name: 'New Zealand'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'FJ',
        name: 'Fiji'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'PG',
        name: 'Papua New Guinea'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SB',
        name: 'Solomon Islands'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'VU',
        name: 'Vanuatu'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NC',
        name: 'Nouvelle-Calédonie'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'PF',
        name: 'Polynésie française'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'TO',
        name: 'Tonga'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CX',
        name: 'Christmas Island'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CK',
        name: 'Cook Islands'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'WS',
        name: 'Samoa Island'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'PN',
        name: 'Pitcairn'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'AS',
        name: 'American Samoa'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'FM',
        name: 'Micronesia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GU',
        name: 'Guam'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MH',
        name: 'Marshall Islands'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MP',
        name: 'Mariana Islands'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'PW',
        name: 'Palau'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CC',
        name: 'Cocos (Keeling) Islands'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'HM',
        name: 'Heard Island and McDonald Islands'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'KI',
        name: 'Kiribati'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NF',
        name: 'Norfolk Island'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NR',
        name: 'Nauru'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NU',
        name: 'Niue'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'TK',
        name: 'Tokelau'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'TV',
        name: 'Tuvalu'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'WF',
        name: 'Wallis and Futuna'
      }
    ],
    code: 'OCE',
    name: 'Oceania'
  },
  {
    countryGroups: [],
    countries: [
      {
        regionGroups: [],
        regions: [],
        code: 'BI',
        name: 'Burundi'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'TD',
        name: 'Chad'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'ER',
        name: 'Eritrea'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'EG',
        name: 'Egypt'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NA',
        name: 'Namibia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'ZA',
        name: 'South Africa'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SL',
        name: 'Sierra Leone'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GW',
        name: 'Guinea-Bissau'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'RW',
        name: 'Rwanda'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SZ',
        name: 'Swaziland'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MA',
        name: 'Morocco'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'TN',
        name: 'Tunisia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SN',
        name: 'Senegal'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CM',
        name: 'Cameroon'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'ZM',
        name: 'Zambia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'TZ',
        name: 'Tanzania'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NG',
        name: 'Nigeria'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'KE',
        name: 'Kenya'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'BW',
        name: 'Botswana'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CI',
        name: 'Côte d’Ivoire'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GH',
        name: 'Ghana'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GN',
        name: 'Guinea'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MR',
        name: 'Mauritania'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'ST',
        name: 'São Tomé and Príncipe'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'ET',
        name: 'Ethiopia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MW',
        name: 'Malawi'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GQ',
        name: 'Equatorial Guinea'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GM',
        name: 'Gambia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'LR',
        name: 'Liberia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SO',
        name: 'Somalia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SC',
        name: 'Seychelles'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'KM',
        name: 'Comoros'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'LS',
        name: 'Lesotho'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'ML',
        name: 'Mali'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'TG',
        name: 'Togo'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'DJ',
        name: 'Djibouti'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GA',
        name: 'Gabon'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MZ',
        name: 'Mozambique'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'UG',
        name: 'Uganda'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'BF',
        name: 'Burkina Faso'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'BJ',
        name: 'Benin'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CG',
        name: 'Congo'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SD',
        name: 'Sudan'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'NE',
        name: 'Niger'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'ZW',
        name: 'Zimbabwe'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'RE',
        name: 'Réunion'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SH',
        name: 'Saint Helena'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'LY',
        name: 'Libya'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CV',
        name: 'Cabo Verde'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'YT',
        name: 'Mayotte'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MG',
        name: 'Madagascar'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'DZ',
        name: 'Algeria'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'AO',
        name: 'Angola'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CD',
        name: 'DR Congo'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CF',
        name: 'République centrafricaine'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SS',
        name: 'South Sudan'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'MU',
        name: 'Mauritius'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'EH',
        name: 'Western Sahara'
      }
    ],
    code: 'AFR',
    name: 'Africa'
  },
  {
    countryGroups: [],
    countries: [
      {
        regionGroups: [],
        regions: [],
        code: 'AR',
        name: 'Argentina'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'EC',
        name: 'Ecuador'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'SR',
        name: 'Suriname'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'BO',
        name: 'Bolivia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'BR',
        name: 'Brazil'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'UY',
        name: 'Uruguay'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CL',
        name: 'Chile'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'CO',
        name: 'Colombia'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'PY',
        name: 'Paraguay'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'PE',
        name: 'Peru'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'VE',
        name: 'Venezuela'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GF',
        name: 'French Guiana'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GY',
        name: 'Guyana'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'FK',
        name: 'Falkland Islands'
      },
      {
        regionGroups: [],
        regions: [],
        code: 'GS',
        name: 'South Georgia'
      }
    ],
    code: 'AMS',
    name: 'South America'
  },
  {
    countryGroups: [
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'CN',
            name: '中国 China'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'JP',
            name: '日本 Japan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KR',
            name: 'South Korea'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TW',
            name: '台湾 Taiwan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'HK',
            name: '香港 Hong Kong'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MO',
            name: '澳门 Macao'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KP',
            name: 'North Korea'
          }
        ],
        code: 'EAS',
        name: 'East Asia'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'IN',
            name: 'India'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'PK',
            name: 'Pakistan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BD',
            name: 'Bangladesh'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'NP',
            name: 'Nepal'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LK',
            name: 'Sri Lanka'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MV',
            name: 'Maldives'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BT',
            name: 'Bhutan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AF',
            name: 'Afghanistan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'IO',
            name: 'BI Ocean'
          }
        ],
        code: 'SAS',
        name: 'South Asia'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'ID',
            name: 'Indonesia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'PH',
            name: 'Philippines'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'VN',
            name: 'Vietnam'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TH',
            name: 'Thailand'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MM',
            name: 'Myanmar Burma'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MY',
            name: 'Malaysia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KH',
            name: 'Cambodia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SG',
            name: 'Singapore'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BN',
            name: 'Brunei'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TL',
            name: 'East Timor'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LA',
            name: 'Laos'
          }
        ],
        code: 'SEA',
        name: 'South East Asia'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'IR',
            name: 'Iran'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'IQ',
            name: 'Iraq'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SA',
            name: 'Saudi Arabia'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'SY',
            name: 'Syria'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'JO',
            name: 'Jordan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'AE',
            name: 'United Arab Emirates'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'LB',
            name: 'Lebanon'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'PS',
            name: 'Palestine'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'OM',
            name: 'Oman'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KW',
            name: 'Kuwait'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'BH',
            name: 'Bahrain'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'QA',
            name: 'Qatar'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'YE',
            name: 'Yemen'
          }
        ],
        code: 'MEA',
        name: 'Middle East'
      },
      {
        countries: [
          {
            regionGroups: [],
            regions: [],
            code: 'UZ',
            name: 'Uzbekistan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KZ',
            name: 'Қазақстан Kazakhstan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'KG',
            name: 'Кыргыз Kyrgyzstan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TM',
            name: 'Turkmenistan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'TJ',
            name: 'Тоҷикистон Tajikistan'
          },
          {
            regionGroups: [],
            regions: [],
            code: 'MN',
            name: 'Монгол Улс Mongolia'
          }
        ],
        code: 'CAS',
        name: 'Central Asia'
      }
    ],
    countries: [],
    code: 'ASI',
    name: 'Asia'
  }
];

export default locations;