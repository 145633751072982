import * as actionTypes from './actionTypes';
import { gameId } from '../constants';
import R from 'ramda';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';

const initialState = {
  entities: [],
  totalClaimedGifts: 0,
  claimedGiftsPerType: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.REMOVE:
      return remove(state, action);
    case actionTypes.ADD:
      return add(state, action);
    case actionTypes.CLAIMED_COUNT:
      return claimedGifts(state, action);
    default:
      return state;
  }
};

const get = compose(
  createAPIStatusReducer('getStatus', 'Failed to fetch gifts'),
  onSuccess((state, action) => {
    const { seriouslyId } = action.meta;
    return {
      ...state,
      entities: {
        ...state.entities,
        [gameId]: {
          ...state.entities[gameId],
          [seriouslyId]: R.propOr([], 'gifts', action.payload)
        }
      }
    };
  })
);

const remove = compose(
  createAPIStatusReducer('removeStatus', 'Failed to remove gift')
);

const add = compose(createAPIStatusReducer('addStatus', 'Failed to add gift'));

const claimedGifts = compose(
  createAPIStatusReducer('claimedStatus', 'Failed to claimed gifts'),
  onSuccess((state, action) => {
    return {
      ...state,
      totalClaimedGifts: action.payload.count,
      claimedGiftsPerType: action.payload.giftTypeCounter
    };
  })
);
