import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const GET = `${parentName}/${NAME}/GET`;
export const GET_PARTIALS = `${parentName}/${NAME}/GET_PARTIALS`;
export const UPDATE = `${parentName}/${NAME}/UPDATE`;
export const SAVE = `${parentName}/${NAME}/SAVE`;
export const RESET = `${parentName}/${NAME}/RESET`;
export const REVERT = `${parentName}/${NAME}/REVERT`;
export const ROLLBACK = `${parentName}/${NAME}/ROLLBACK`;
export const COPY_FROM = `${parentName}/${NAME}/COPY_FROM`;
export const ADD_DEPENDENCY = `${parentName}/${NAME}/ADD_DEPENDENCY`;
export const RELEASE_DEPENDENCY = `${parentName}/${NAME}/RELEASE_DEPENDENCY`;
