import * as actionTypes from './actionTypes';
import R from 'ramda';
import { gameIds } from '../constants';

let initialEntities = R.reduce(
  (obj, key) => {
    obj[key] = [];
    return obj;
  },
  {},
  gameIds
);

let initialStatus = R.reduce(
  (obj, key) => {
    obj[key] = {};
    return obj;
  },
  {},
  gameIds
);

const initialState = {
  entities: initialEntities, // Map of gameId = player array for that game.
  searchResults: [],
  loadedPlayer: null,
  getStatus: initialStatus,
  locationSaveStatus: null,
  searchStatus: null,
  searchesPending: 0,
  saveInfractionStatus: null,
  relationGetStatus: null,
  fetchLogStatus: null,
  lastSearchId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_SOCIAL_NETWORK_DATA: //returns user profile
    case actionTypes.SET_USER_INFO:
    case actionTypes.SET_SOCIAL_PROVIDER:
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.GET_MANY:
      return getMany(state, action);
    case actionTypes.SAVE_LOCATION:
      return saveLocation(state, action);
    case actionTypes.SEARCH:
      return search(state, action);
    case actionTypes.SET_CURRENTLY_LOADED:
      return setCurrentlyLoaded(state, action);
    case actionTypes.ADD_INFRACTION:
    case actionTypes.TOGGLE_INFRACTIONS:
    case actionTypes.ADD_COMMENT:
    case actionTypes.CONVERT_CHEATER_DATA:
    case actionTypes.REPLACE_COUNTERS:
    case actionTypes.RESOLVE_ALL_INFRACTIONS:
      return saveInfractionData(state, action);
    case actionTypes.GET_RELATIONS:
      return getRelations(state, action);
    case actionTypes.FETCH_LOG:
      return fetchLog(state, action);
    case actionTypes.ADD_LOG_ENTRY:
    case actionTypes.ADD_LOG_ENTRIES:
      return addEntry(state, action);
    default:
      return state;
  }
};

const get = (state, action) => {
  let { gameId, seriouslyId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      getStatus: setStatus(state.getStatus, gameId, seriouslyId, 'pending')
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus: setStatus(
        state.getStatus,
        gameId,
        seriouslyId,
        'Player not found.'
      )
    };
  } else {
    return {
      ...state,
      getStatus: removeStatus(state.getStatus, gameId, seriouslyId),
      entities: {
        ...state.entities,
        [gameId]: addPlayer(state.entities[gameId], action.payload)
      }
    };
  }
};

const getMany = (state, action) => {
  let { seriouslyIds, gameId } = action.meta;
  if (action.pending) {
    let newGetStatus = {};
    for (const id of seriouslyIds)
      newGetStatus = setStatus(state.getStatus, gameId, id, 'pending');
    return {
      ...state,
      getStatus: newGetStatus
    };
  } else if (action.error) {
    let newGetStatus = {};
    for (const id of seriouslyIds)
      newGetStatus = setStatus(
        state.getStatus,
        gameId,
        id,
        'Player not found.'
      );
    return {
      ...state,
      getStatus: newGetStatus
    };
  } else {
    let newGetStatus = {};
    let entities = state.entities;
    let newPlayers = [];
    for (const id of seriouslyIds) {
      newGetStatus = removeStatus(state.getStatus, gameId, id);
      newPlayers.push(R.propOr(null, id, action.payload));
    }
    entities = {
      ...entities,
      [gameId]: addPlayers(entities[gameId], newPlayers)
    };
    return {
      ...state,
      getStatus: newGetStatus,
      entities
    };
  }
};

const getRelations = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      relationGetStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      relationGetStatus: 'Relations not found.',
      relatedAccounts: {}
    };
  } else {
    return {
      ...state,
      relationGetStatus: null,
      relatedAccounts: action.payload
    };
  }
};

const saveInfractionData = (state, action) => {
  let { gameId } = action.meta;

  if (action.pending) {
    return {
      ...state,
      saveInfractionStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      saveInfractionStatus: 'Failed to save cheater data'
    };
  } else {
    return {
      ...state,
      saveInfractionStatus: null,
      entities: {
        ...state.entities,
        [gameId]: addPlayer(state.entities[gameId], action.payload)
      }
    };
  }
};

const saveLocation = (state, action) => {
  let { gameId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      locationSaveStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      locationSaveStatus: 'Failed to save location..'
    };
  } else {
    return {
      ...state,
      locationSaveStatus: null,
      entities: {
        ...state.entities,
        [gameId]: addPlayer(state.entities[gameId], action.payload)
      }
    };
  }
};

const search = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      lastSearchId: action.transactionId,
      searchStatus: 'pending',
      searchesPending: state.searchesPending + 1
    };
  } else if (action.error) {
    return {
      ...state,
      searchStatus: action.payload || 'Failed to search for player',
      searchesPending: state.searchesPending - 1
    };
  } else {
    let searchResults = state.searchResults;
    if (action.transactionId === state.lastSearchId)
      searchResults = action.payload.searchResults;
    return {
      ...state,
      searchStatus: null,
      searchesPending: state.searchesPending - 1,
      searchResults
    };
  }
};

const setCurrentlyLoaded = (state, action) => {
  const { seriouslyId } = action.payload;
  return {
    ...state,
    loadedPlayer: seriouslyId
  };
};

const addEntry = state => {
  return {
    ...state
  };
};

const fetchLog = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      fetchLogStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      fetchLogStatus: action.error
    };
  } else {
    return {
      ...state,
      fetchLogStatus: null,
      csLog: action.payload
    };
  }
};

/************* Utilities *************/

const addPlayer = (entities, player) => addPlayers(entities, [player]);
const addPlayers = (entities, players) =>
  R.unionWith(R.eqBy(R.prop('seriouslyId')), players, entities);

const setStatus = (statuses, gameId, id, status) =>
  R.assocPath([gameId, id], status, statuses);
const removeStatus = (statuses, gameId, id) =>
  R.dissocPath([gameId, id], statuses);
