import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  entities: [],
  getStatus: null,
  getAllStatus: null,
  updateStatus: null,
  createStatus: null,
  deleteStatus: null,

  // If user tries to save a config which overlaps with some other VersionConfig,
  // the server returns and error and a overlappedConfig property which is the id of the other VersionConfig.
  latestOverlappingConfigId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.GET_ALL:
      return getAll(state, action);
    case actionTypes.UPDATE:
      return update(state, action);
    case actionTypes.CREATE:
      return create(state, action);
    case actionTypes.DELETE:
      return deleteById(state, action);
    default:
      return state;
  }
};

const get = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus: action.payload || 'Failed to get version configs.'
    };
  } else {
    return {
      ...state,
      entities: addVersionConfig(state.entities, action.payload),
      getStatus: null
    };
  }
};

const getAll = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getAllStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getAllStatus: action.payload || 'Failed to get all version configs.'
    };
  } else {
    return {
      ...state,
      entities: addVersionConfigs(state.entities, action.payload),
      getAllStatus: null
    };
  }
};

const update = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      updateStatus: 'pending',
      latestOverlappingConfigId: null
    };
  } else if (action.error) {
    return {
      ...state,
      updateStatus: action.payload || 'Failed to update version config.',
      latestOverlappingConfigId: R.pathOr(
        null,
        ['meta', 'overlappedConfig'],
        action
      )
    };
  } else {
    return {
      ...state,
      entities: addVersionConfig(state.entities, action.payload),
      updateStatus: null,
      latestOverlappingConfigId: null
    };
  }
};

const create = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      createStatus: 'pending',
      latestOverlappingConfigId: null
    };
  } else if (action.error) {
    return {
      ...state,
      createStatus: action.payload || 'Failed to create version config.',
      latestOverlappingConfigId: R.pathOr(
        null,
        ['meta', 'overlappedConfig'],
        action
      )
    };
  } else {
    return {
      ...state,
      entities: addVersionConfig(state.entities, action.payload),
      createStatus: null,
      latestOverlappingConfigId: null
    };
  }
};

const deleteById = (state, action) => {
  const { id } = action.meta;
  if (action.pending) {
    return {
      ...state,
      deleteStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      deleteStatus: action.payload || 'Failed to delete version config.'
    };
  } else {
    return {
      ...state,
      entities: R.reject(R.propEq('id', parseInt(id)), state.entities),
      deleteStatus: null
    };
  }
};

/******** Utils ************/

const addVersionConfigs = (entities, versionConfigs) =>
  R.unionWith(R.eqBy(R.prop('id')), versionConfigs, entities).sort((a, b) =>
    compareVersions(a.targetVersionFrom, b.targetVersionFrom)
  );
const addVersionConfig = (entities, versionConfig) =>
  addVersionConfigs(entities, [versionConfig]);

const compareVersions = (versionA, versionB) => {
  const numericA = R.sum(
    versionA.split('.').map((v, i) => parseInt(v) * Math.pow(255, 3 - i))
  );
  const numericB = R.sum(
    versionB.split('.').map((v, i) => parseInt(v) * Math.pow(255, 3 - i))
  );
  return numericB - numericA;
};
