import { combineReducers } from 'redux';
import * as supportCodesConstants from './supportCodes/constants';
import supportCodesReducer from './supportCodes/reducer';
import * as supportCodeTemplatesConstants from './supportCodeTemplates/constants';
import supportCodeTemplatesReducer from './supportCodeTemplates/reducer';
import * as versionConfigConstants from './versionConfigs/constants';
import versionConfigReducer from './versionConfigs/reducer';
import * as levelConfigConstants from './levelConfig/constants';
import * as eventsConstants from './events/constants';
import eventsReducer from './events/reducer';
import * as imagesConstants from './images/constants';
import imagesReducer from './images/reducer';
import * as analyticsConstants from './analytics/constants';
import analyticsReducer from './analytics/reducer';
import * as leaderboardsConstants from './leaderboards/constants';
import leaderboardsReducer from './leaderboards/reducer';
import * as friendsConstants from './friends/constants';
import friendsReducer from './friends/reducer';
import * as giftsConstants from './gifts/constants';
import giftsReducer from './gifts/reducer';
import * as playersConstants from './players/constants';
import playersReducer from './players/reducer';
import * as inventorysConstants from './inventorys/constants';
import inventorysReducer from './inventorys/reducer';
import * as inventoryHistorysConstants from './inventoryHistorys/constants';
import inventoryHistorysReducer from './inventoryHistorys/reducer';
import * as receiptsConstants from './receipts/constants';
import receiptsReducer from './receipts/reducer';
import * as searchConstants from './search/constants';
import searchReducer from './search/reducer';
import * as globalTeamEventConstants from './globalTeamEvent/constants';
import globalTeamEventReducer from './globalTeamEvent/reducer';
import * as devToolsConstants from './devTools/constants';
import devToolsReducer from './devTools/reducer';
import * as contentdeliveryframeworkConstants from './contentdeliveryframework/constants';
import contentdeliveryframeworkReducer from './contentdeliveryframework/reducer';

export default combineReducers({
  [supportCodesConstants.NAME]: supportCodesReducer,
  [supportCodeTemplatesConstants.NAME]: supportCodeTemplatesReducer,
  [versionConfigConstants.NAME]: versionConfigReducer,
  [eventsConstants.NAME]: eventsReducer,
  [imagesConstants.NAME]: imagesReducer,
  [analyticsConstants.NAME]: analyticsReducer,
  [leaderboardsConstants.NAME]: leaderboardsReducer,
  [friendsConstants.NAME]: friendsReducer,
  [inventorysConstants.NAME]: inventorysReducer,
  [inventoryHistorysConstants.NAME]: inventoryHistorysReducer,
  [playersConstants.NAME]: playersReducer,
  [receiptsConstants.NAME]: receiptsReducer,
  [giftsConstants.NAME]: giftsReducer,
  [searchConstants.NAME]: searchReducer,
  [globalTeamEventConstants.NAME]: globalTeamEventReducer,
  [devToolsConstants.NAME]: devToolsReducer,
  [contentdeliveryframeworkConstants.NAME]: contentdeliveryframeworkReducer
});
