import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  entities: [],
  createStatus: null,
  getAllStatus: null,
  removeStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL:
      return getAll(state, action);
    case actionTypes.CREATE:
      return create(state, action);
    case actionTypes.REMOVE:
      return remove(state, action);
    default:
      return state;
  }
};

const getAll = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getAllStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getAllStatus:
        action.payload.message || 'Failed to get support code templates'
    };
  } else {
    return {
      ...state,
      getAllStatus: null,
      entities: addSupportCodeTemplates(state.entities, action.payload)
    };
  }
};

const create = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      createStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      createStatus:
        action.payload.message || 'Failed to create support code template'
    };
  } else {
    return {
      ...state,
      createStatus: null,
      entities: addSupportCodeTemplate(state.entities, action.payload)
    };
  }
};

const remove = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      removeStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      removeStatus:
        action.payload.message || 'Failed to remove support code template'
    };
  } else {
    const { name } = action.meta;
    return {
      ...state,
      removeStatus: null,
      entities: removeSupportCodeTemplateByName(state.entities, name)
    };
  }
};

/****** Utils *********/

const addSupportCodeTemplates = (entities, supportCodes) =>
  R.unionWith(R.eqBy(R.prop('name')), supportCodes, entities);
const addSupportCodeTemplate = (entities, supportCode) =>
  addSupportCodeTemplates(entities, [supportCode]);
const removeSupportCodeTemplateByName = (entities, name) =>
  R.reject(R.propEq('name', name), entities);
