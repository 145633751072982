import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  entities: [],
  invites: [],
  seriouslyId: null,
  getStatus: null,
  invitesStatus: null,
  refreshStatus: null,
  refreshFBStatus: null,
  unfriendStatus: null,
  addFriendStatus: null,
  copyStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.ADD:
      return add(state, action);
    case actionTypes.REFRESH:
      return refresh(state, action);
    case actionTypes.REFRESH_FB_FRIENDS:
      return refreshFacebook(state, action);
    case actionTypes.UNFRIEND:
      return unfriend(state, action);
    case actionTypes.BREAK_FRIENDSHIP:
      return breakFriendship(state, action);
    case actionTypes.GET_INVITES:
      return getInvites(state, action);
      case actionTypes.COPY:
      return copy(state, action);
    default:
      return state;
  }
};

const get = (state, action) => {
  const { seriouslyId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus: 'Failed to fetch friends'
    };
  } else {
    let removedFriends = [];
    let activeFriends = [];
    if (action.payload) {
      activeFriends = R.filter(
        R.propSatisfies(
          v => !R.equals('RemovedFriends', v),
          'friendListProvider'
        ),
        action.payload
      );
      removedFriends = R.filter(
        R.propSatisfies(
          v => R.equals('RemovedFriends', v),
          'friendListProvider'
        ),
        action.payload
      );
    }

    return {
      ...state,
      getStatus: null,
      seriouslyId,
      entities: { activeFriends, removedFriends }
    };
  }
};

const getInvites = (state, action) => {
  const { seriouslyId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      invitesStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      invitesStatus: 'Failed to fetch friends'
    };
  } else {
    return {
      ...state,
      invitesStatus: null,
      seriouslyId,
      invites: action.payload
    };
  }
};

const add = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      addFriendStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      addFriendStatus: 'Failed to add friend'
    };
  } else {
    return {
      ...state,
      addFriendStatus: null
    };
  }
};

const refresh = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      refreshStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      refreshStatus: 'Failed to refresh friends'
    };
  } else {
    return {
      ...state,
      refreshStatus: null
    };
  }
};

const refreshFacebook = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      refreshFBStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      refreshFBStatus: 'Failed to refresh Facebook friends'
    };
  } else {
    return {
      ...state,
      refreshFBStatus: null
    };
  }
};

const unfriend = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      unfriendStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      unfriendStatus: 'Failed to break friendship'
    };
  } else {
    return {
      ...state,
      unfriendStatus: null
    };
  }
};

const breakFriendship = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      unfriendStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      unfriendStatus: action.error
    };
  } else {
    return {
      ...state,
      unfriendStatus: null
    };
  }
};

const copy = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      copyStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      copyStatus: 'Failed to copy friends'
    };
  } else {
    return {
      ...state,
      copyStatus: null
    };
  }
};
