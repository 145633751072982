import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const GET = `${parentName}/${NAME}/GET`;
export const GET_MANY = `${parentName}/${NAME}/GET_MANY`;
export const GET_RELATIONS = `${parentName}/${NAME}/GET_RELATIONS`;
export const SAVE_LOCATION = `${parentName}/${NAME}/SAVE_LOCATION`;
export const SEARCH = `${parentName}/${NAME}/SEARCH`;
export const SET_CURRENTLY_LOADED = `${parentName}/${NAME}/SET_CURRENTLY_LOADED`;
export const ADD_INFRACTION = `${parentName}/${NAME}/ADD_INFRACTION`;
export const TOGGLE_INFRACTIONS = `${parentName}/${NAME}/TOGGLE_INFRACTIONS`;
export const ADD_COMMENT = `${parentName}/${NAME}/ADD_COMMENT`;
export const RESOLVE_ALL_INFRACTIONS = `${parentName}/${NAME}/RESOLVE_ALL_INFRACTIONS`;
export const CONVERT_CHEATER_DATA = `${parentName}/${NAME}/CONVERT_CHEATER_DATA`;
export const FETCH_LOG = `${parentName}/${NAME}/FETCH_LOG`;
export const ADD_LOG_ENTRY = `${parentName}/${NAME}/ADD_LOG_ENTRY`;
export const ADD_LOG_ENTRIES = `${parentName}/${NAME}/ADD_LOG_ENTRIES`;
export const REMOVE_PUBLIC_ID = `${parentName}/${NAME}/REMOVE_PUBLIC_ID`;
export const REMOVE_SOCIAL_NETWORK_DATA = `${parentName}/${NAME}/REMOVE_SOCIAL_NETWORK_DATA`;
export const SET_USER_INFO = `${parentName}/${NAME}/SET_USER_INFO`;
export const REPLACE_COUNTERS = `${parentName}/${NAME}/REPLACE_COUNTERS`;
export const SET_SOCIAL_PROVIDER = `${parentName}/${NAME}/SET_SOCIAL_PROVIDER`;
