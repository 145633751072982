import * as actionTypes from './actionTypes';
import R from 'ramda';
import * as receiptParser from './components/receiptParser';
import { compose, createAPIStatusReducer, onSuccess, onPending } from '~/reducerUtils';

const initialState = {
  playerReceipts: [],
  relatedReceipts: [],
  lastCursor: null,
  lastReceiptType: null,
  lastCount: 0,
  lastRelatedCursor: null,
  lastRelatedReceiptType: null,
  lastRelatedCount: 0,
  lastRelatedSeriouslyIdIndex: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.GET_RELATED:
      return getRelated(state, action);
    default:
      return state;
  }
};

const get = compose(
  createAPIStatusReducer('getStatus', 'Failed to get receipts'),
  onPending((state) => {
    return {
      ...state,
      lastCount: 0,
      lastCursor: null,
      lastReceiptType: null
    }
  }),
  onSuccess((state, action) => {
    const { seriouslyId } = action.meta;
    const {
      newReceipts,
      oldReceipts
    } = action.payload;
    const allReceipts = [
      ...unifyReceiptFormat(newReceipts, false, false, seriouslyId),
      ...unifyReceiptFormat(oldReceipts, true, false, seriouslyId)
    ];
    return {
      ...state,
      lastCount: allReceipts.length,
      lastCursor: action.payload.cursor,
      lastReceiptType: action.payload.receiptType,
      playerReceipts: allReceipts
    };
  })
)

const getRelated = compose(
  createAPIStatusReducer('getRelatedStatus', 'Failed to get related receipts'),
  onPending((state) => {
    return {
      ...state,
      lastRelatedCount: 0,
      lastRelatedCursor: null,
      lastRelatedReceiptType: null,
      lastRelatedSeriouslyIndex: null
    }
  }),
  onSuccess((state, action) => {
    const { seriouslyId } = action.meta;
    const {
      newReceipts,
      oldReceipts
    } = action.payload;
    const allReceipts = [
      ...unifyReceiptFormat(newReceipts, false, true, seriouslyId),
      ...unifyReceiptFormat(oldReceipts, true, true, seriouslyId)
    ];
    return {
      ...state,
      lastRelatedCount: allReceipts.length,
      lastRelatedCursor: action.payload.cursor,
      lastRelatedReceiptType: action.payload.receiptType,
      lastRelatedSeriouslyIdIndex: action.payload.seriouslyIdIndex,
      relatedReceipts: allReceipts
    };
  })
)

function unifyReceiptFormat(receipts, isOld, isRelated, seriouslyId) {
  if (receipts === null || receipts === undefined) return []
  return [
    ...receipts.map(r => {
      r = R.assoc('oldFormat', isOld, r);
      r = R.assoc('relatedReceipt', isRelated, r);

      r = R.assoc('productId', receiptParser.getProductId(r, isOld), r);
      const status = receiptParser.getStatus(r, isOld);
      r = R.assoc('status', status.status, r);
      r = R.assoc('validity', status.validity, r);
      if (receiptParser.isGPReceipt(r, isOld))
        r = R.assoc('orderId', receiptParser.getOrderId(r, isOld), r);

      if (isOld) {
        r = R.assoc('timestamp', r.createdAt, r);
        r = R.assoc('platform', r.store.toLowerCase(), r);
        if (r.rawReceipt !== null) //old format is missing datastore id, create a unique one from either rawReceipt or seriouslyid + timestamp
          r = R.assoc('datastoreId', r.rawReceipt, r);
        else
          r = R.assoc('datastoreId', `${r.seriouslyId}_${r.createdAt}`, r);
      }
      if (isRelated) {
        r = R.assoc('platform', r.platform.toLowerCase(), r);
        r = R.assoc('relatedSeriouslyId', r.seriouslyId, r);
        r = R.assoc('seriouslyId', seriouslyId, r);
      }
      return r;
    })
  ];
}