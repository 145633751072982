import resourceIcons from './resourceIcons';
import countries from './countries';
import locations from './locations';
import germanyStates from './germanyStates';
import russiaStates from './russiaStates';
import usStates from './usStates';
import ukStates from './ukStates';
import eventStreamPresets from './eventStreamPresets';

const eventStreamIndex = DASHBOARD_ENV === 'production'
  ? 'game-prod-bf1-*'
  : DASHBOARD_ENV === 'development'
  ? 'game-dev-bf1-*'
  : 'game-qa-bf1-*';

const createVentureEvent = (key, currentVersion) => ({
  field: `Venture_${key}_fwdCompatible`,
  title: `${key} Goal Number (${currentVersion})`
});

export const NAME = 'bestfiends';
export const gameIds = ['bf'];
export const gameId = 'bf';
export const maxFiendSkins = 10;
export const maxFiendLevel = 50;
export const evolutionSkinCount = 5;
export const levelsInFiendQuest = 30;
export const getVentureEvents = (currentVersion, collectionEvents) => collectionEvents.map(e => createVentureEvent(e.id, currentVersion));

export {
  resourceIcons,
  countries,
  locations,
  germanyStates,
  russiaStates,
  usStates,
  ukStates,
  eventStreamPresets,
  eventStreamIndex,
};
