import bfCommonReducer from './bf-common/reducer';
import * as bfCommonConstants from './bf-common/constants';
import * as bestFiendsConstants from './bestfiends/constants';
import bestFiendsReducer from './bestfiends/reducer';
import * as phoenixConstants from './phoenix/constants';
import phoenixReducer from './phoenix/reducer';
import * as coreConstants from './core/constants';
import coreReducer from './core/reducer';
import * as companyConstants from './company/constants';
import companyReducer from './company/reducer';
import { connectRouter } from 'connected-react-router';

export const reducers = history => {
  return {
    [coreConstants.NAME]: coreReducer,
    [bfCommonConstants.NAME]: bfCommonReducer,
    [phoenixConstants.NAME]: phoenixReducer,
    [bestFiendsConstants.NAME]: bestFiendsReducer,
    [companyConstants.NAME]: companyReducer,
    router: connectRouter(history)
  };
};
