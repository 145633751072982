import * as actionTypes from './actionTypes';
import R from 'ramda';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';

const initialState = {
  entities: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.SET:
      return set(state, action);
    case actionTypes.INCREASE:
      return increase(state, action);
    case actionTypes.CREATE:
      return create(state, action);
    case actionTypes.DELETE:
      return deleteEvent(state, action);
    default:
      return state;
  }
};

const get = compose(
  createAPIStatusReducer('getStatus', 'Failed to fetch global team event'),
  onSuccess((state, action) => {
    return {
      ...state,
      entities: action.payload
    };
  })
);

const set = compose(
  createAPIStatusReducer('setStatus', 'Failed to set values for global team event')
);

const increase = compose(
  createAPIStatusReducer('increaseStatus', 'Failed to increase values for global team event')
);

const create = compose(
  createAPIStatusReducer('createStatus', 'Failed to create global team event')
);

const deleteEvent = compose(
  createAPIStatusReducer('deleteStatus', 'Failed to delete global team event')
);
