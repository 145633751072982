import * as actionTypes from './actionTypes';
import R from 'ramda';
import { mapServerEventToDashboardEvent } from './model';

const initialState = {
  entities: [],

  promotedEntity: {
    id: null,
    doesntExist: null,
    entity: null
  },

  getAllStatus: null,
  getStatus: null,
  saveStatus: null,
  createStatus: null,
  uploadImagesStatus: null,
  deleteStatus: null,
  getPromotedStatus: null,
  promotionStatus: null,

  // When cloning an event, the event is set here so temporarily so that the new route can fetch it from here.
  cloneEvent: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL:
      return getAll(state, action);
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.CREATE:
      return create(state, action);
    case actionTypes.SAVE:
      return save(state, action);
    case actionTypes.UPLOAD_IMAGES:
      return uploadImages(state, action);
    case actionTypes.DELETE:
      return deleteEvent(state, action);
    case actionTypes.SET_CLONE:
      return setClone(state, action);
    case actionTypes.CLEAR_CLONE:
      return clearClone(state);
    case actionTypes.GET_PROMOTED:
      return getPromoted(state, action);
    case actionTypes.PROMOTION_CREATE:
      return promotionCreate(state, action);
    case actionTypes.SET_PROMOTION_STATUS:
      return setPromotionStatus(state, action);
    default:
      return state;
  }
};

const setPromotionStatus = (state, action) => ({
  ...state,
  promotionStatus: action.payload.status
});

const getPromoted = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getPromotedStatus: 'pending',
      promotedEntity: {
        id: action.meta.id,
        doesntExist: null,
        entity: null
      }
    };
  } else if (action.error) {
    if (action.status !== 404) {
      return {
        ...state,
        getPromotedStatus:
          !R.isNil(action.errors) && action.errors.length > 0
            ? action.errors
            : 'Failed to get promoted event'
      };
    } else {
      return {
        ...state,
        getPromotedStatus: null,
        promotedEntity: {
          ...state.promotedEntity,
          doesntExist: true
        }
      };
    }
  } else {
    let event = mapServerEventToDashboardEvent(action.payload);
    return {
      ...state,
      getPromotedStatus: null,
      promotedEntity: {
        ...state.promotedEntity,
        entity: event
      },
      promotedEnityDoesntExist: null
    };
  }
};

const setClone = (state, action) => ({
  ...state,
  cloneEvent: action.payload.event
});

const clearClone = state => ({
  ...state,
  cloneEvent: null
});

const getAll = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getAllStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getAllStatus:
        !R.isNil(action.errors) && action.errors.length > 0
          ? action.errors
          : 'Failed to get events'
    };
  } else {
    let events = action.payload.map(mapServerEventToDashboardEvent);
    return {
      ...state,
      getAllStatus: null,
      entities: addEvents(state.entities, events)
    };
  }
};

const get = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus:
        !R.isNil(action.errors) && action.errors.length > 0
          ? action.errors
          : 'Failed to get event'
    };
  } else {
    let event = mapServerEventToDashboardEvent(action.payload);
    return {
      ...state,
      getStatus: null,
      entities: addEvent(state.entities, event)
    };
  }
};

const save = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      saveStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      saveStatus:
        !R.isNil(action.errors) && action.errors.length > 0
          ? action.errors
          : 'Failed to save event'
    };
  } else {
    let event = mapServerEventToDashboardEvent(action.payload);
    event = R.assoc('images', action.meta.event.images, event);
    return {
      ...state,
      saveStatus: null,
      entities: addEvent(state.entities, event)
    };
  }
};

const create = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      createStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      createStatus:
        !R.isNil(action.errors) && action.errors.length > 0
          ? action.errors
          : 'Failed to create event'
    };
  } else {
    let event = mapServerEventToDashboardEvent(action.payload);
    return {
      ...state,
      createStatus: null,
      entities: addEvent(state.entities, event)
    };
  }
};

const uploadImages = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      uploadImagesStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      uploadImagesStatus:
        !R.isNil(action.errors) && action.errors.length > 0
          ? action.errors
          : 'Failed to upload event images'
    };
  } else {
    let event = mapServerEventToDashboardEvent(action.payload);
    return {
      ...state,
      uploadImagesStatus: null,
      entities: addEvent(state.entities, event)
    };
  }
};

const deleteEvent = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      deleteStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      deleteStatus:
        !R.isNil(action.errors) && action.errors.length > 0
          ? action.errors
          : 'Failed to delete event'
    };
  } else {
    return {
      ...state,
      deleteStatus: null,
      entities: R.reject(R.propEq('id', action.meta.event.id), state.entities)
    };
  }
};

const promotionCreate = (state, action) => {
  if (!action.pending && !action.error) {
    let event = mapServerEventToDashboardEvent(action.payload);
    return {
      ...state,
      promotedEntity: {
        ...state.promotedEntity,
        entity: event,
        doesntExist: false
      }
    };
  } else {
    return state;
  }
};

// utils

const addEvents = (entities, events) =>
  R.unionWith(R.eqBy(R.prop('id')), events, entities);
const addEvent = (entities, event) => addEvents(entities, [event]);
