import * as actionTypes from './actionTypes';
import R from 'ramda';
import { gameIds } from '../constants';
import dayjs from '~/dayjs';

let initialEntities = R.reduce(
  (obj, key) => {
    obj[key] = [];
    return obj;
  },
  {},
  gameIds
);

const initialState = {
  entities: initialEntities,
  getStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    default:
      return state;
  }
};

const get = (state, action) => {
  const { seriouslyId, gameId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus:
        action.payload || `Failed to fetch inventory history for ${seriouslyId}`
    };
  } else {
    let inventoryHistory = {
      seriouslyId,
      data: action.payload
    };
    inventoryHistory.data = R.filter(
      filterReallyOldHistory,
      inventoryHistory.data
    ); //We can't guarantee that all inventories in the history are good to rollback to, because we do change inventory format every now and then.
    return {
      ...state,
      getStatus: null,
      entities: {
        ...state.entities,
        [gameId]: addInventoryHistory(state.entities[gameId], inventoryHistory)
      }
    };
  }
};

/******* Utils  *************/
const addInventoryHistory = (historys, history) =>
  R.unionWith(R.eqBy(R.prop('seriouslyId')), historys, [history]);
const filterReallyOldHistory = data => dayjs(data.timestamp).year() >= 2017;
