export const BLACK_LIST_EVENT_TYPES = [
  "TrackingPreparationPopupShown",
  "VenturePinataTooltipOpened",
  "PrivacyConsent",
  "AdFailure",
  "DDResultClient",
  "ExternalLinkReward",
  "NetworkTimeout",
  "AdvertisementNetworkRanOutOfAds",
  "DynamicDifficultyAccumulatedEvent",
  "WheelSpinCompletedForEnergyWheel",
  "BundleIntegrity",
  "BundleLoaded",
  "GachaKeysViaVideoAdsTriggered",
  "PurchaseCapReached",
  "SuspiciousActivity",
  "GameLoadingFunnel",
  "UserRatedApp",
  "WheelSpinButtonPressedForEnergyWheel",
  "SurveyLinkShown",
  "MapLoadTime",
  "TargetedAdsConsentStatusUpdated",
  "HackerDetected",
  "GoldenTicketAmountChanged",
  "CrossPromo",
  "FreeFiendUpgradesAmountChanged",
  "DDAccumulationClient",
  "GachaKeysViaVideoAdsDeclined",
  "GachaKeysViaVideoAdsResult",
  "YoutubeVideoWatched",
  "CoppaAgeConsent",
  "iOSATTPopupShown",
  "ServerReconnect",
  "HackerRevoked ",
  "AdFailed",
  "SalePopupShown",
  "VideoPlayerResult",
  "RuntimeDataIntegrityCheck",
  "GemAmountChanged",
  "SurveyCompleted",
  "AdFailedToLoad",
  "KeysGained",
  "SeriouslyFriendsConfirmation",
  "PsfException",
  "HackedPlayer",
  "QuizTaken",
  "BundleWallOffer",
  "MinutianNewsCenter",
  "GrindChallengeRestarted",
  "LugsLootEventProgress",
  "CheckListEventProgress",
  "InboxOpened",
  "SocialTickerShown",
  "HackedBinary",
  "FixingFiends",
  "PsfGameSettingsDataGot",
  "PiggyBankFillStateUpdated",
  "AccountDeletionStarted",
  "GemConversion",
  "BundlesDownloaded",
  "BoardGameMegaDiceProgress",
  "WheelSpinButtonPressedForGoldenWheel",
  "HIAP",
  "FriendManagement",
  "PiggyBank",
  "AdvertisementRanOutOfAds",
  "WinStreakViaAdsPopUp",
  "UIAP",
  "DynamicDifficultyResult",
  "AdWheelForcedSpinOpened",
  "BoardGameDiceEarned",
  "MessagingStatus",
  "TimeElapsedBeforePlayPressed",
  "WheelSpinCompletedForGoldenWheel",
  "WinStreakRecovered",
  "GrindChallengeStarted",
  "balance_update",
  "SessionStarted"
];
