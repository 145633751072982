import R from 'ramda';
import Serializer from '~/bf-common/utils/Serializer';

export const updateSerialized = (inventory, serializedData) => {
  var { data, metadata } = Serializer.deserialize(serializedData);
  return {
    ...inventory,
    serializedData,
    data,
    metadata
  };
};

const mappers = {};

// Registers a mapper object which allows mapping of inventory data. See comment below.
export const registerInventoryMapper = (gameId, mapper) => {
  if (!isMapper(mapper))
    throw new Error(
      '[inventory.model] registerInventoryMapper: Trying to register mapper which does not implement the interface.'
    );
  if (R.isNil(mappers[gameId])) mappers[gameId] = [];
  mappers[gameId].push(mapper);
};

const isMapper = mapper =>
  !R.isNil(mapper.onGet) ||
  !R.isNil(mapper.onUpdate) ||
  !R.isNil(mapper.onSave);

/**
 *  mapOn* functions are used in the inventorys module reducer to make game specific transforms to the inventory data on GET and UPDATE.
 *
 *  Original use case for this was keeping the old and new fiend data formats in sync (August 2016). Doing it this way allows the SchemaForm to only edit the simpler old fiend data, which
 *  is then mapped to the new fiend data in these mapping functions.
 *
 *  Right now it's only function is to increment the 'codesRedeemed' value in BF1 (see bestfiends/mapper.js), which ensures that editing inventory doesn't trigger cheater detection.
 *  This could be refactored away...
 */

export const mapOnGet = (gameId, inventory) => {
  if (!R.isNil(mappers[gameId])) {
    const onGets = R.reject(R.isNil, mappers[gameId].map(R.prop('onGet')));
    if (!R.isEmpty(onGets)) return R.pipe.apply(R, onGets)(inventory);
  }
  return inventory;
};

export const mapOnUpdate = (gameId, oldInventory, newInventory) => {
  if (!R.isNil(mappers[gameId])) {
    const onUpdates = R.reject(
      R.isNil,
      mappers[gameId].map(R.prop('onUpdate'))
    );
    if (!R.isEmpty(onUpdates))
      return R.pipe.apply(R, onUpdates)(oldInventory, newInventory);
  }
  return newInventory;
};

export const mapOnSave = (gameId, localInventory, serverInventory) => {
  if (!R.isNil(mappers[gameId])) {
    const onSaves = R.reject(R.isNil, mappers[gameId].map(R.prop('onSave')));
    if (!R.isEmpty(onSaves))
      return R.pipe.apply(R, onSaves)(localInventory, serverInventory);
  }
  return localInventory;
};

export const getUpdates = (updatedInventry, oldInventory) => {
  if (R.isNil(updatedInventry) || R.isNil(oldInventory)) return {};

  const originalKeys = R.keys(oldInventory.serializedData);
  const editedKeys = R.keys(updatedInventry.serializedData);
  const commonKeys = R.intersection(originalKeys, editedKeys);
  const newKeys = R.difference(editedKeys, originalKeys);
  let updatedKeys = R.union(commonKeys, newKeys);

  const updatedValues = {};
  for (var i in updatedKeys) {
    const key = updatedKeys[i];
    if (
      updatedInventry.serializedData[key] !== oldInventory.serializedData[key]
    )
      updatedValues[key] = updatedInventry.serializedData[key];
  }

  return updatedValues;
};

export const getDeletes = (updatedInventry, oldInventory) => {
  if (R.isNil(updatedInventry) || R.isNil(oldInventory)) return [];

  const originalKeys = R.keys(oldInventory.serializedData);
  const editedKeys = R.keys(updatedInventry.serializedData);
  const deletedKeys = R.difference(originalKeys, editedKeys);

  return deletedKeys;
};
