import R from 'ramda';

export const mapServerEventToDashboardEvent = event => {
  // We map the customData object to key-value pairs for concistent editing.
  // This is mapped back to
  let mappedCustomData = [];
  if (event.customData) {
    mappedCustomData = R.keys(event.customData).map(key => ({
      key,
      value: event.customData[key]
    }));
  }
  return R.assoc('customData', mappedCustomData, event);
};

export const mapDashboardEventToServerEvent = event => {
  const customData = R.reduce(
    (curr, { key, value }) => {
      curr[key] = value;
      return curr;
    },
    {},
    event.customData
  );

  event = R.assoc('customData', customData, event);

  const unChangedImages = event.images
    ? event.images.filter(img => R.isNil(img.previewUrl))
    : null;
  return R.assoc('images', unChangedImages, event);
};
