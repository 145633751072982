import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  entities: [],
  createStatus: null,
  getStatus: null,
  getAllStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE:
      return create(state, action);
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.GET_ALL:
      return getAll(state, action);
    case actionTypes.CLEAR:
      return clear(state, action);
    default:
      return state;
  }
};

const create = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      createStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      createStatus: action.payload.message || 'Failed to create support code'
    };
  } else {
    return {
      ...state,
      createStatus: null,
      entities: addSupportCode(state.entities, action.payload)
    };
  }
};

const get = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus:
        action.payload.message ||
        (action.status === 404
          ? 'Support code not found'
          : 'Failed to get support code')
    };
  } else {
    return {
      ...state,
      getStatus: null,
      entities: addSupportCode(state.entities, action.payload)
    };
  }
};

const getAll = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getAllStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getAllStatus: action.payload.message || 'Failed to get all support codes'
    };
  } else {
    return {
      ...state,
      getAllStatus: null,
      entities: addSupportCodes(state.entities, action.payload)
    };
  }
};

const clear = () => initialState;

/****** Utils  *******/
const addSupportCodes = (supportCodes, supportCode) =>
  R.sort(
    dateSort,
    R.unionWith(R.eqBy(R.prop('code')), supportCodes, supportCode)
  );
const addSupportCode = (supportCodes, supportCode) =>
  addSupportCodes(supportCodes, [supportCode]);

const dateSort = (a, b) => (a.createdAt < b.createdAt ? 1 : -1);
