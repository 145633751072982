import R from 'ramda';
import * as actionTypes from './actionTypes';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';

const initialState = {
  firstTimeCheaters: [],
  firstTimeLimit: 0,
  earlyUnlocks: [],
  lastLeaderboardChecked: null,
  lastCheckTimestamp: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIRST_TIME_CHEATERS:
      return firstTimeCheaters(state, action);
    case actionTypes.EARLY_UNLOCKS:
      return earlyUnlocks(state, action);
    default:
      return state;
  }
};

const firstTimeCheaters = compose(
  createAPIStatusReducer(
    'firstTimeCheaterStatus',
    'Failed to fetch first time cheaters'
  ),
  onSuccess((state, action) => {
    return {
      ...state,
      firstTimeCheaters: action.payload.seriouslyIds,
      firstTimeLimit: action.meta.limit
    };
  })
);

const earlyUnlocks = compose(
  createAPIStatusReducer('earlyUnlockStatus', 'Failed to fetch early unlocks'),
  onSuccess((state, action) => {
    const tasks = action.payload;
    //this is bit ugly as the early unlock server returns json as string
    const results = R.filter(
      v => !v.includes('"responseEntities":[]'),
      R.pluck('result', tasks)
    );
    let entities = [];
    for (var i = 0; i < results.length; i++)
      entities.push(JSON.parse(results[i]));
    const players = flat(R.pluck('responseEntities', entities));

    //get the last leaderboard checked and it's timestamp
    let lastResults = null;
    let timestamp = null;
    if (tasks.length > 0) {
      const parsedResult = JSON.parse(tasks[0].result);
      lastResults = parsedResult.leaderboardsChecked;

      timestamp = tasks[0].startTime;
    }

    return {
      ...state,
      earlyUnlocks: players,
      lastLeaderboardChecked: lastResults,
      lastCheckTimestamp: timestamp
    };
  })
);

function flat(input, depth = 1, stack = []) {
  for (let item of input) {
    if (item instanceof Array && depth > 0) {
      flat(item, depth - 1, stack);
    }
    else {
      stack.push(item);
    }
  }

  return stack;
}
