import * as actionTypes from './actionTypes';
import R from 'ramda';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';

const initialState = {
  created: [],
  errors: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TICKERS:
      return createTickers(state, action);
    default:
      return state;
  }
};


const createTickers = compose(
  createAPIStatusReducer('createStatus', 'Failed to create social tickers. Please check all parameters.'),
  onSuccess((state, action) => {
    return {
      ...state,
      created: action.payload.created,
      errors: action.payload.errors
    };
  })
);