import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  leaderboard: null,
  meta: null,
  getLeaderboardStatus: null,
  getUnlockStatus: null,
  metaStatus: null,
  regionStatus: {},
  regions: {},
  unlockData: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_META:
      return getMeta(state, action);
    case actionTypes.GET_REGIONS_BY_COUNTRY:
      return getRegionsByCountry(state, action);
    case actionTypes.GET_LEADERBOARD:
      return getLeaderboard(state, action);
    case actionTypes.GET_UNLOCK:
      return getUnlock(state, action);
    default:
      return state;
  }
};

const getLeaderboard = (state, action) => {
  const {
    leaderboardId,
    continent,
    countryGroup,
    country,
    region,
    limit,
    offset,
    type
  } = action.meta;

  if (action.pending) {
    return {
      ...state,
      getLeaderboardStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getLeaderboardStatus: action.payload || 'Failed to fetch leaderboard'
    };
  } else {
    return {
      ...state,
      getLeaderboardStatus: null,
      leaderboard: {
        leaderboardId,
        continent,
        countryGroup,
        country,
        region,
        limit,
        offset,
        type,
        scores: action.payload.scores,
        name: action.payload.name,
        timeZoneHourShift: action.payload.timeZoneHourShift,
        timeZoneShiftInWinter: action.payload.timeZoneShiftInWinter
      }
    };
  }
};

const getUnlock = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getUnlockStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getUnlockStatus: action.payload || 'Failed to fetch unlock times'
    };
  } else {
    return {
      ...state,
      getUnlockStatus: null,
      unlockData: action.payload
    };
  }
};

const getMeta = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      metaStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      metaStatus: action.payload || 'Failed to fetch metadata'
    };
  } else {
    return {
      ...state,
      metaStatus: null,
      meta: action.payload
    };
  }
};

const getRegionsByCountry = (state, action) => {
  const { country } = action.meta;
  if (action.pending) {
    return {
      ...state,
      regionStatus: setRegionStatus(state, country, 'pending')
    };
  } else if (action.error) {
    return {
      ...state,
      regionStatus: setRegionStatus(
        state,
        country,
        action.payload || 'Failed to fetch metadata'
      )
    };
  } else {
    return {
      ...state,
      regionStatus: setRegionStatus(state, country, null),
      regions: R.assoc(country, action.payload, state.regions)
    };
  }
};

// Utils

const setRegionStatus = (state, country, status) =>
  R.assoc(country, status, state.regionStatus);
