import energyIcon from '../assets/Icon_Energy.png';
import unlimitedEnergyIcon from '../assets/Icon_Energy_Unlimited.png';
import gemIcon from '../assets/Icon_Gem.png';
import goldIcon from '../assets/Icon_Gold.png';
import keyIcon from '../assets/Icon_Key.png';
import yellowMiteIcon from '../assets/Icon_Yellow_Mite.png';
import blueMiteIcon from '../assets/Icon_Blue_Mite.png';
import goldenLevelIcon from '../assets/Icon_Golden_Level.png';
import goldenTicketIcon from '../assets/Icon_Golden_Challenge.png';
import megaBoostIcon from '../assets/Icon_Mega_Boost.png';
import vipIcon from '../assets/Icon_VIP_crown.png';
import preLevelBoosterDoubleBloomIcon from '../assets/Icon_Double_Bloom.png';
import preLevelBoosterFireCrackerIcon from '../assets/Icon_Fire_Crackers.png';
import preLevelBoosterMagicStaffIcon from '../assets/Icon_Magic_Staff.png';
import preLevelBoosterRocketIcon from '../assets/Icon_Rocket.png';
import preLevelBoosterSlugSprayIcon from '../assets/Icon_Slug_Spray.png';

export const ENERGY_ICON_KEY = 'energy';
export const UNLIMITED_ENERGY_ICON_KEY = 'unlimitedEnergy';
export const GEM_ICON_KEY = 'gem';
export const GOLD_ICON_KEY = 'gold';
export const KEY_ICON_KEY = 'key';
export const YELLOW_MITE_ICON_KEY = 'yellowMite';
export const BLUE_MITE_ICON_KEY = 'blueMite';
export const GOLDEN_LEVEL_ICON_KEY = 'goldenLevel';
export const GOLDEN_TICKET_ICON_KEY = 'goldenTicket';
export const MEGA_BOOST_ICON_KEY = 'megaBoost';
export const VIP_ICON_KEY = 'vip';
export const PRE_LEVEL_BOOSTER_DOUBLE_BLOOM_KEY = 'preLevelBoosterDoubleBloom';
export const PRE_LEVEL_BOOSTER_FIRE_CRACKER_KEY = 'preLevelBoosterFireCracker';
export const PRE_LEVEL_BOOSTER_MAGIC_STAFF_KEY = 'preLevelBoosterMagicStaff';
export const PRE_LEVEL_BOOSTER_ROCKET_KEY = 'preLevelBoosterRocket';
export const PRE_LEVEL_BOOSTER_SLUG_SPRAY_KEY = 'preLevelBoosterSlugSpray';

export const mapKeyToName = (key: string) => {
  switch (key) {
    case ENERGY_ICON_KEY:
      return 'Energy';
    case UNLIMITED_ENERGY_ICON_KEY:
      return 'Unlimited Energy';
    case GEM_ICON_KEY:
      return 'Gems';
    case GOLD_ICON_KEY:
      return 'Gold';
    case KEY_ICON_KEY:
      return 'Keys';
    case YELLOW_MITE_ICON_KEY:
      return 'Yellow Mites';
    case BLUE_MITE_ICON_KEY:
      return 'Blue Mites';
    case GOLDEN_LEVEL_ICON_KEY:
      return 'Golden Levels';
    case GOLDEN_TICKET_ICON_KEY:
      return 'Golden Tickets';
    case MEGA_BOOST_ICON_KEY:
      return 'Mega Boost';
    case VIP_ICON_KEY:
      return 'VIP';
    case PRE_LEVEL_BOOSTER_DOUBLE_BLOOM_KEY:
      return 'Double Blooms';
    case PRE_LEVEL_BOOSTER_FIRE_CRACKER_KEY:
      return 'Fire Crackers';
    case PRE_LEVEL_BOOSTER_MAGIC_STAFF_KEY:
      return 'Magic Staffs';
    case PRE_LEVEL_BOOSTER_ROCKET_KEY:
      return 'Rockets';
    case PRE_LEVEL_BOOSTER_SLUG_SPRAY_KEY:
      return 'Slug Sprays';
    default:
      console.warn(`Unmapped reward key ${key}.`);
      return key;
  }
}

const resourceIcons = {
  [ENERGY_ICON_KEY]: energyIcon,
  [UNLIMITED_ENERGY_ICON_KEY]: unlimitedEnergyIcon,
  [GEM_ICON_KEY]: gemIcon,
  [GOLD_ICON_KEY]: goldIcon,
  [KEY_ICON_KEY]: keyIcon,
  [YELLOW_MITE_ICON_KEY]: yellowMiteIcon,
  [BLUE_MITE_ICON_KEY]: blueMiteIcon,
  [GOLDEN_LEVEL_ICON_KEY]: goldenLevelIcon,
  [GOLDEN_TICKET_ICON_KEY]: goldenTicketIcon,
  [MEGA_BOOST_ICON_KEY]: megaBoostIcon,
  [VIP_ICON_KEY]: vipIcon,
  [PRE_LEVEL_BOOSTER_DOUBLE_BLOOM_KEY]: preLevelBoosterDoubleBloomIcon,
  [PRE_LEVEL_BOOSTER_FIRE_CRACKER_KEY]: preLevelBoosterFireCrackerIcon,
  [PRE_LEVEL_BOOSTER_MAGIC_STAFF_KEY]: preLevelBoosterMagicStaffIcon,
  [PRE_LEVEL_BOOSTER_ROCKET_KEY]: preLevelBoosterRocketIcon,
  [PRE_LEVEL_BOOSTER_SLUG_SPRAY_KEY]: preLevelBoosterSlugSprayIcon
}

export default resourceIcons;