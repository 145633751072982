import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const GET = `${parentName}/${NAME}/GET`;
export const ADD = `${parentName}/${NAME}/ADD`;
export const REFRESH = `${parentName}/${NAME}/REFRESH`;
export const REFRESH_FB_FRIENDS = `${parentName}/${NAME}/REFRESH_FB_FRIENDS`;
export const UNFRIEND = `${parentName}/${NAME}/UNFRIEND`;
export const BREAK_FRIENDSHIP = `${parentName}/${NAME}/BREAK_FRIENDSHIP`;
export const GET_INVITES = `${parentName}/${NAME}/INVITES`;
export const COPY = `${parentName}/${NAME}/COPY`;
