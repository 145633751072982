export default {
  deserialize: function(input) {
    if (typeof input !== 'object')
      throw new Error('Serializer.deserialize: input is not an object.');
    var result = {};
    for (var key in input) {
      var value = input[key];
      var ret = deserialize(key, value);
      result = mergeItems(result, ret || {});
    }
    var finalized = finalize(result);
    return { data: finalized.data, metadata: finalized.metadata };
  },
  serialize: function(input, metadata) {
    if (typeof metadata === 'undefined') metadata = {};
    if (typeof input !== 'object')
      throw new Error(
        'Serializer.serialize: input is not an object. Input = ' +
          JSON.stringify(input)
      );
    var result = {};
    for (var key in input) {
      var value = input[key];
      var ret = serialize(key, value, metadata, 'root.' + key);
      result = mergeItems(result, ret || {});
    }
    return result;
  },

  // Exposing to allow unit testing
  _splitByKeyWords: splitByKeyWords,
  _mergeItems: mergeItems
};

const CSharpMinValue = -62135596800;
function serialize(key, value, metadata, path) {
  var ret, result, i;
  if (Array.isArray(value)) {
    // List
    ret = {};
    ret['list_' + key + '_count'] = value.length;
    for (i in value) {
      result = serialize(
        'list_' + key + '_' + i,
        value[i],
        metadata,
        path + '.' + i
      );
      ret = mergeItems(result, ret || {});
    }
  } else if (value instanceof Date) {
    // Date
    ret = {};
    if (value.getTime() / 1000 >= CSharpMinValue) {
      setDate(ret, key, value.getTime() / 1000);
    } else {
      // This is to handle the fact that C# System.DateTime.MinValue > Javascript Date min value.
      setDate(ret, key, CSharpMinValue);
    }
  } else if (typeof value === 'object' && value !== null) {
    ret = {};
    if (metadata[path] === 'dict') {
      var count = 0;
      for (i in value) {
        result = serialize(
          'dict_' + key + '_' + count + '_value',
          value[i],
          metadata,
          path + '.' + i
        );
        ret = mergeItems(result, ret || {});
        result = serialize(
          'dict_' + key + '_' + count + '_key',
          i,
          metadata,
          path + '.' + i
        );
        ret = mergeItems(result, ret);
        count++;
      }
      ret['dict_' + key + '_count'] = count;
    } else {
      ret['class_' + key] = true;
      for (i in value) {
        result = serialize(
          'class_' + key + '_field_' + i,
          value[i],
          metadata,
          path + '.' + i
        );
        ret = mergeItems(result, ret || {});
      }
    }
  } else {
    ret = {};
    ret[key] = value;
  }
  return ret;
}

function setDate(ret, key, unixTime) {
  ret['date_' + key + '_unixTime'] = '' + Math.floor(unixTime);
}

function deserialize(key, value) {
  var match, parentKey, ret, arr;
  var keySplit = splitByKeyWords(key);
  if ((match = key.match(/^list_(.+)_count$/))) {
    // List count
    parentKey = match[1];
    var count = parseInt(value);
    arr = new SerializeArray();
    arr.setCount(count);
    return deserialize(parentKey, arr);
  } else if ((match = key.match(/^list_(.+)_(\d+)$/))) {
    // List item
    parentKey = match[1];
    var index = parseInt(match[2]);
    arr = new Array(index + 1);
    arr[index] = value;
    return deserialize(parentKey, new SerializeArray(arr));
  } else if ((match = key.match(/^dict_(.+)_count$/))) {
    // Dict count
    parentKey = match[1];
    var count = parseInt(value);
    var dict = new SerializeDictionary();
    dict.setCount(count);
    return deserialize(parentKey, dict);
  } else if ((match = key.match(/^dict_(.+)_(\d+)_key$/))) {
    // Dict key
    parentKey = match[1];
    var index = parseInt(match[2]);
    var dict = new SerializeDictionary();
    dict.setIndexKey(index, value);
    return deserialize(parentKey, dict);
  } else if ((match = key.match(/^dict_(.+)_(\d+)_value$/))) {
    // Dict value
    parentKey = match[1];
    var index = parseInt(match[2]);
    var dict = new SerializeDictionary();
    dict.setIndexValue(index, value);
    return deserialize(parentKey, dict);
  } else if (
    (match = key.match(/^class_(.+)$/)) &&
    keySplit[keySplit.length - 2] !== 'field'
  ) {
    // Class definition
    parentKey = match[1];
    return deserialize(parentKey, {});
  } else if (
    (match = key.match(/^class_(.+)_field_(.+)$/)) &&
    keySplit[keySplit.length - 2] === 'field'
  ) {
    parentKey = match[1];
    var field = match[2];
    ret = {};
    ret[field] = value;
    return deserialize(parentKey, ret);
  } else if ((match = key.match(/^date_(.+)_unixTime$/))) {
    // Unix timestamp
    parentKey = match[1];
    var date = new Date(parseInt(value) * 1000);
    return deserialize(parentKey, date);
  } else {
    ret = {};
    ret[key] = value;
    return ret;
  }
}

// list_class_someClass_field_some_Value_0 -> [list, class, someClass, field, some_Value, 0]
const keywords = [
  'class',
  'list',
  'field',
  'dict',
  'value',
  'key',
  'date',
  'unixTime'
];
function splitByKeyWords(key) {
  var keySplit = key.split('_');
  var split = [];
  var endSplit = [];
  var word = [];
  for (var i = 0; i < keySplit.length; i++) {
    var token = keySplit[i];
    if (keywords.indexOf(token) != -1) {
      if (word.length > 0) {
        split.push(word.join('_'));
        word = [];
      }
      split.push(token);
      if (token === 'list') {
        endSplit.push(keySplit.pop());
      }
    } else {
      word.push(token);
    }
  }

  if (word.length > 0) split.push(word.join('_'));
  Array.prototype.push.apply(split, endSplit);
  return split;
}

/**
 *  Converts Serializer helper class instances to plain JS objects and
 *  generates metadata which is needed when serializing the plain JS objects.
 */
function finalize(obj, metadata, path) {
  if (typeof path === 'undefined') path = 'root';
  if (typeof metadata === 'undefined') metadata = {};

  var ret, i;
  if (Array.isArray(obj)) {
    ret = [];
    for (i in obj) {
      ret[i] = finalize(obj[i], metadata, path + '.' + i).data;
    }
    return { data: ret, metadata: metadata };
  } else if (typeof obj === 'object' && obj !== null) {
    if (hasKeys(obj, ['year', 'month', 'day', 'hour', 'minute', 'second'])) {
      // Is date (old format)
      return {
        data: new Date(
          Date.UTC(
            obj.year,
            obj.month - 1,
            obj.day,
            obj.hour,
            obj.minute,
            obj.second,
            obj.millisecond
          )
        ),
        metadata: metadata
      };
    } else if (obj instanceof SerializeArray) {
      var result = finalize(obj.toJsArray(), metadata, path);
      return { data: result.data, metadata: metadata };
    } else if (obj instanceof SerializeDictionary) {
      var result = finalize(obj.toJsObject(), metadata, path);
      metadata[path] = 'dict';
      return { data: result.data, metadata: metadata };
    } else if (obj instanceof Date) {
      return { data: obj, metadata: metadata };
    } else {
      ret = {};
      for (i in obj) {
        ret[i] = finalize(obj[i], metadata, path + '.' + i).data;
      }
      return { data: ret, metadata: metadata };
    }
  } else {
    return { data: obj, metadata: metadata };
  }
}

function hasKeys(obj, keys) {
  for (var i = 0; i < keys.length; i++) {
    if (!obj.hasOwnProperty(keys[i])) return false;
  }
  return true;
}

function SerializeArray(values) {
  this.values = values;
}

SerializeArray.prototype.setCount = function(count) {
  this.count = count;
};

SerializeArray.prototype.merge = function(other) {
  var dst = mergeItems(this.values || [], other.values || []);
  var count = null;
  if (typeof this.count === 'number') {
    if (this.count < dst.length) dst = dst.slice(0, this.count);
    count = this.count;
  }
  if (typeof other.count === 'number') {
    if (other.count < dst.length) dst = dst.slice(0, other.count);
    count = other.count;
  }
  var arr = new SerializeArray(dst);
  if (count != null) arr.setCount(count);
  return arr;
};

SerializeArray.prototype.toJsArray = function() {
  return this.values;
};

function SerializeDictionary() {
  this.values = {};
}

SerializeDictionary.prototype.setCount = function(count) {
  if (count > 0) this.count = count;
};

SerializeDictionary.prototype.merge = function(other) {
  var values = other.values;
  for (var index in this.values) {
    var obj = this.values[index];
    if (typeof other.values[index] === 'undefined') values[index] = obj;
    else values[index] = mergeItems(obj, other.values[index], index);
  }
  var merged = new SerializeDictionary();
  merged.setCount(this.count > 0 ? this.count : other.count);
  merged.values = values;
  return merged;
};

SerializeDictionary.prototype.toJsObject = function() {
  var obj = {};
  for (var i in this.values) {
    var item = this.values[i];
    obj[item.key] = item.value;
  }
  return obj;
};

SerializeDictionary.prototype.setIndexKey = function(index, key) {
  this.values[index] = shallowMerge(this.values[index], {
    key: key
  });
};

SerializeDictionary.prototype.setIndexValue = function(index, value) {
  this.values[index] = shallowMerge(this.values[index], {
    value: value
  });
};

function shallowMerge(a, b) {
  var obj = {};
  if (typeof a !== 'undefined') {
    for (var i in a) obj[i] = a[i];
  }
  if (typeof b !== 'undefined') {
    for (var i in b) obj[i] = b[i];
  }
  return obj;
}

/**
 * Modified version of KyleAMathews/deepmerge.
 * Changed to merge arrays by index and handle SerializeArray & SerializeDictionary.
 */
function mergeItems(target, src, key) {
  var array = Array.isArray(src);
  var dst = (array && []) || {};

  if (array) {
    target = target || [];
    dst = dst.concat(target);
    src.forEach(function(e, i) {
      if (typeof dst[i] === 'undefined') {
        dst[i] = e;
      } else if (typeof e === 'object') {
        dst[i] = mergeItems(target[i], e, i);
      } else if (typeof e !== 'undefined') {
        while (dst.length < i + 1) dst.push(undefined);
        dst[i] = e;
      }
    });
  } else {
    if (src instanceof SerializeArray || target instanceof SerializeArray) {
      if (
        !(src instanceof SerializeArray) ||
        !(target instanceof SerializeArray)
      ) {
        // Trying to merge a SerializeArray to a normal object, so we just default to one of them.
        console.error(
          `Trying to merge a SerializeArray to a normal object with key ${key}`
        );
        return target;
      } else {
        var merge = src.merge(target);
        return merge;
      }
    } else if (
      src instanceof SerializeDictionary ||
      target instanceof SerializeDictionary
    ) {
      if (
        !(src instanceof SerializeDictionary) ||
        !(target instanceof SerializeDictionary)
      ) {
        // Trying to merge a SerializeDictionary to a normal object, so we just default to one of them.
        console.error(
          `Trying to merge a SerializeDictionary to a normal object with key ${key}`
        );
        return target;
      } else {
        var merge = src.merge(target);
        return merge;
      }
    } else {
      if (target instanceof Date) {
        dst = target;
      } else if (src instanceof Date) {
        dst = src;
      } else {
        if (target && typeof target === 'object') {
          Object.keys(target).forEach(function(key) {
            dst[key] = target[key];
          });
        }
        Object.keys(src).forEach(function(key) {
          if (typeof src[key] !== 'object' && typeof dst[key] !== 'object') {
            dst[key] = src[key];
          } else {
            if (typeof target[key] === 'undefined') {
              dst[key] = src[key];
            } else if (src[key] instanceof Date) {
              dst[key] = src[key];
            } else if (!(target[key] instanceof Date)) {
              dst[key] = mergeItems(target[key], src[key], key);
            }
          }
        });
      }
    }
  }

  return dst;
}

// isArray polyfill.
if (!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}
