import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const GET_ALL = `${parentName}/${NAME}/GET_ALL`;
export const GET = `${parentName}/${NAME}/GET`;
export const SAVE = `${parentName}/${NAME}/SAVE`;
export const CREATE = `${parentName}/${NAME}/CREATE`;
export const UPLOAD_IMAGES = `${parentName}/${NAME}/UPLOAD_IMAGES`;
export const DELETE = `${parentName}/${NAME}/DELETE`;
export const SET_CLONE = `${parentName}/${NAME}/SET_CLONE`;
export const CLEAR_CLONE = `${parentName}/${NAME}/CLEAR_CLONE`;

export const GET_PROMOTED = `${parentName}/${NAME}/GET_PROMOTED`;
export const PROMOTION_ERROR = `${parentName}/${NAME}/PROMOTION_ERROR`;
export const PROMOTION_CREATE = `${parentName}/${NAME}/PROMOTION_CREATE`;
export const PROMOTION_UPLOAD_IMAGES = `${parentName}/${NAME}/PROMOTION_UPLOAD_IMAGES`;
export const SET_PROMOTION_STATUS = `${parentName}/${NAME}/SET_PROMOTION_STATUS`;
