import * as actionTypes from './actionTypes';
import R from 'ramda';
import dayjs from '~/dayjs';
import { DEFAULT_LOAD_COUNT } from './constants';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';
import { BLACK_LIST_EVENT_TYPES } from './blackListEventsTypes'

const initialState = {
  seriouslyId: null,
  events: [],
  types: null,
  filters: {
    date: dayjs().toDate(),
    mode: 'allEvents',
    eventTypes: [],
    trackProps: [],
    highlight: false,
    count: DEFAULT_LOAD_COUNT
  },
  currentlyLoadedFilters: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.GET_TYPES:
      return getEventTypes(state, action);
    case actionTypes.SET_FILTERS:
      return setFilters(state, action);
    case actionTypes.CLEAR_EVENTS:
      return clearEvents(state, action);
    default:
      return state;
  }
};

const clearEvents = state => ({
  ...state,
  events: []
});

const setFilters = compose(
  createAPIStatusReducer('setFiltersStatus', 'Failed to set filters'),
  onSuccess((state, action) => {
    return {
      ...state,
      filters: action.payload
    };
  })
);

const getEventTypes = compose(
  createAPIStatusReducer('getTypesStatus', 'Failed to get events'),
  onSuccess((state, action) => {
    return {
      ...state,
      types: action.payload.filter((eventType) => {
        return !BLACK_LIST_EVENT_TYPES.includes(eventType);
      }),
    };
  })
);

const get = compose(
  createAPIStatusReducer('getStatus', 'Failed to get events'),
  onSuccess((state, action) => {
    const { seriouslyId } = action.meta;
    let events = [...state.events, ...action.payload];
    return {
      ...state,
      seriouslyId,
      events: sortEvents(R.uniqBy(R.path(['data', 'saEventNonce']), events)),
      currentlyLoadedFilters: state.filters
    };
  })
);

/*** utils ****/

// Sort by event time. If two event times are the same, sort by name.
const sortEvents = R.sort((a, b) => {
  let aa = R.path(['data', 'saEventTime'], a);
  let bb = R.path(['data', 'saEventTime'], b);
  if (bb === aa) {
    aa = R.path(['data', 'saEventName'], a);
    bb = R.path(['data', 'saEventName'], b);
  }

  return aa < bb ? 1 : aa > bb ? -1 : 0;
});

R.descend(R.path(['data', 'saEventTime']));
