const russiaStates = [
  {
    name: 'Санкт-Петербург / St. Petersburg',
    code: 'spe'
  },
  {
    name: 'Москва / Moscow',
    code: 'mow'
  },
  {
    name: 'Амурская область / Amurskaya oblast (east)',
    code: 'amu'
  },
  {
    name: 'Камчатский край / Kamchatsky kray (east)',
    code: 'kam'
  },
  {
    name: 'Хабаровский край / Khabarovsky kray (east)',
    code: 'kha'
  },
  {
    name: 'Магаданская область / Magadanskaya oblast (east)',
    code: 'mag'
  },
  {
    name: 'Приморский край  / Primorsky kray (east)',
    code: 'pri'
  },
  {
    name: 'Сахалинская область / Sakhalinskya oblast (east)',
    code: 'sak'
  },
  {
    name: 'Еврейская автономная обасть / Yevreyskaya avtonomnaya oblast (east)',
    code: 'yev'
  },
  {
    name: 'Алтайский край / Altayskiy kray (siberia)',
    code: 'alt'
  },
  {
    name: 'Бурятия республика / Buryatia respublica (siberia)',
    code: 'bu'
  },
  {
    name: 'Иркусткая область / Irkutskaya oblast (siberia)',
    code: 'irk'
  },
  {
    name: 'Кемеровская область / Kemerovskaya oblast (siberia)',
    code: 'kem'
  },
  {
    name:
      'Ханты-Мансинский автономный округ, югра / Khantu-Mansiyskiy AO, Yougra(siberia)',
    code: 'khm'
  },
  {
    name: 'Красноярский край / Krasnoyarsky kray (siberia)',
    code: 'kya'
  },
  {
    name: 'Новосибирская область / Novosibirskaya oblast (siberia)',
    code: 'nvs'
  },
  {
    name: 'Республика Саха / Respublica Sakha (siberia)',
    code: 'sa'
  },
  {
    name: 'Томская область / Tomskaya oblast (siberia)',
    code: 'tom'
  },
  {
    name: 'Тюменская / Tyumenskaya oblast (siberia)',
    code: 'tyu'
  },
  {
    name: 'ЯНАО / YANAO (siberia)',
    code: 'yan'
  },
  {
    name: 'Архангельская область / Arkhangelskaya oblast (north)',
    code: 'ark'
  },
  {
    name: 'Калининградская область / Kaliningradskaya oblast (north)',
    code: 'kgd'
  },
  {
    name: 'Коми / Respublica Komi (north)',
    code: 'ko'
  },
  {
    name: 'Карелия / Kareliya (north)',
    code: 'kr'
  },
  {
    name: 'Ленинградская область / Leningradskaya oblast (north)',
    code: 'len'
  },
  {
    name: 'Псковская область / Pskovskaya oblast (north)',
    code: 'psk'
  },
  {
    name: 'Мурманская область / Murmanskaya oblast (north)',
    code: 'mur'
  },
  {
    name: 'Республика Адыгея / Respublica Adyugeya (central)',
    code: 'ad'
  },
  {
    name: 'Астраханская область / Astrakhanskaya oblast (central)',
    code: 'ast'
  },
  {
    name: 'Республика Башкортостан / Respublica Bashorkostan (central)',
    code: 'ba'
  },
  {
    name: 'Белгородская область / Belgorodskaya oblast (central)',
    code: 'bel'
  },
  {
    name: 'Брянская область / Bryanskata oblast (central)',
    code: 'bry'
  },
  {
    name: 'Челябинская область / Chelyabinskaya oblast (central)',
    code: 'che'
  },
  {
    name: 'Дагестан / Dagestan (central)',
    code: 'da'
  },
  {
    name: 'Ивановская область / Ivanovskaya oblast (central)',
    code: 'iva'
  },
  {
    name: 'Кабардино-Балкария / Kabardino-Balkariya (central)',
    code: 'kb'
  },
  {
    name: 'Карачаево-Черкесия / Karachaevo-Cherkesiya(central)',
    code: 'kc'
  },
  {
    name: 'Краснодарский край / Krasnodarskiy kray (central)',
    code: 'kda'
  },
  {
    name: 'Курганская область / Kurganskaya oblast (central)',
    code: 'kgn'
  },
  {
    name: 'Кировская область / Kirovskaya oblast (central)',
    code: 'kir'
  },
  {
    name: 'Хакасия / Khakasiya (central)',
    code: 'kk'
  },
  {
    name: 'Калмыкия / Kalmyukiya (central)',
    code: 'kl'
  },
  {
    name: 'Калужская область / Kalujskaya oblast (central)',
    code: 'klu'
  },
  {
    name: 'Московская область / Moskovskaya oblast (central)',
    code: 'mos'
  },
  {
    name: 'Ростовская область / Rostovskaya oblast (central)',
    code: 'ros'
  },
  {
    name: 'Ставропольский край / Stavropolskiy kray (central)',
    code: 'sta'
  },
  {
    name: 'Волгоградская область / Volgogradskaya oblast (central)',
    code: 'vgg'
  },
  {
    name: 'Владимирская область / Vladimirskaya oblast (central)',
    code: 'vla'
  },
  {
    name: 'Татарстан / Tatarstan (central)',
    code: 'ta'
  },
  {
    name: 'Костромская область / Kostromskatya oblast (central)',
    code: 'kos'
  },
  {
    name: 'Курская область / Kurskaya oblast (central)',
    code: 'krs'
  },
  {
    name: 'Липецкая область / Lipetskaya oblast (central)',
    code: 'lip'
  },
  {
    name: 'Марий Эл / Mariy El (central)',
    code: 'me'
  },
  {
    name: 'Мордовия / Mordoviya (central)',
    code: 'mo'
  },
  {
    name: 'Новгородская область / Novgorodskaya oblast (central)',
    code: 'ngr'
  },
  {
    name: 'Нижегородская область / Nijegorodskaya oblast (central)',
    code: 'niz'
  },
  {
    name: 'Омская область / Omskaya oblast (central)',
    code: 'oms'
  },
  {
    name: 'Оренбуржская область / Orenburgskaya oblast (central)',
    code: 'ore'
  },
  {
    name: 'Орловская область / Orlovskaya oblast (central)',
    code: 'orl'
  },
  {
    name: 'Пермский край / Permskiy kray (central)',
    code: 'per'
  },
  {
    name: 'Пензенская область / Penzenskaya oblast (central)',
    code: 'pnz'
  },
  {
    name: 'Рязанская область / Ryazanskaya oblast (central)',
    code: 'rya'
  },
  {
    name: 'Самарская область / Samarskaya oblast (central)',
    code: 'sam'
  },
  {
    name: 'Саратовская область / Saratovskaya oblast (central)',
    code: 'sar'
  },
  {
    name: 'Северная осетия / Severnaya Osetiya (central)',
    code: 'se'
  },
  {
    name: 'Смоленская область / Smolenskaya oblast (central)',
    code: 'smo'
  },
  {
    name: 'Свердловская область / Sverdlovskaya oblast (central)',
    code: 'sve'
  },
  {
    name: 'Тамбовская область / Tambovskaya oblast (central)',
    code: 'tam'
  },
  {
    name: 'Тульская / Tulskaya oblast (central)',
    code: 'tul'
  },
  {
    name: 'Тверская область / Tverskaya oblast (central)',
    code: 'tve'
  },
  {
    name: 'Удмуртия / Udmurtiya (central)',
    code: 'ud'
  },
  {
    name: 'Ульяновская область / Ulyanovskaya oblast (central)',
    code: 'uly'
  },
  {
    name: 'Вологодская область / Vologodskaya oblast(central)',
    code: 'vlg'
  },
  {
    name: 'Воронежская область / Voronejskaya oblast (central)',
    code: 'vor'
  },
  {
    name: 'Ярославская область / Yaroslavskaya oblast (central)',
    code: 'yar'
  }
];

export default russiaStates;