const germanyStates = [
  {
    name: 'Baden-Württemberg',
    code: 'bw'
  },
  {
    name: 'Bayern',
    code: 'by'
  },
  {
    name: 'Berlin',
    code: 'be'
  },
  {
    name: 'Brandenburg (Old)',
    code: 'br'
  },
  {
    name: 'Brandenburg (New)',
    code: 'bb'
  },
  {
    name: 'Bremen',
    code: 'hb'
  },
  {
    name: 'Hamburg',
    code: 'hh'
  },
  {
    name: 'Hessen',
    code: 'he'
  },
  {
    name: 'Mecklenburg-Vorpommern',
    code: 'mv'
  },
  {
    name: 'Niedersachsen',
    code: 'ni'
  },
  {
    name: 'Nordrhein-Westfalen',
    code: 'nw'
  },
  {
    name: 'Rheinland-Pfalz',
    code: 'rp'
  },
  {
    name: 'Saarland',
    code: 'sl'
  },
  {
    name: 'Sachsen',
    code: 'sn'
  },
  {
    name: 'Sachsen-Anhalt',
    code: 'st'
  },
  {
    name: 'Schleswig-Holstein',
    code: 'sh'
  },
  {
    name: 'Thüringen',
    code: 'th'
  }
];

export default germanyStates;